import React, {useState, useEffect} from "react"
import styled from "styled-components"
import ExternalLink from "../Core/ExternalLink"
import Facebook from "../../resources/img/social/facebook.svg"
import Instagram from "../../resources/img/social/instagram.svg"
import LinkedIn from "../../resources/img/social/linkedin.svg"
import breakpoints from "../../styles/breakpoints"
import FacebookDark from "../../resources/img/social/facebook-dark.svg"
import InstagramDark from "../../resources/img/social/instagram-dark.svg"
import LinkedInDark from "../../resources/img/social/linkedin-dark.svg"

const SocialLink = styled(ExternalLink)`
  text-decoration: none;
  display: block;

  img {
    height: 12px;
    margin-right: 24px;
  }

  @media (min-width: ${breakpoints.sm}) {
    width: max-content;

    img {
      height: 12px;
      margin-right: 24px;
    }
  }
`

const SocialLinks = () => {
  const [hovered, setHovered] = useState(null)
  const socials = [
    {icon: Facebook, iconDark: FacebookDark, alt: "Stephen Saada Facebook", link: "https://www.facebook.com/Stephen-Saada-Architecte-337872897921/"},
    {icon: Instagram, iconDark: InstagramDark, alt: "Stephen Saada Instagram", link: "https://www.instagram.com/stephensaada/"},
    {icon: LinkedIn, iconDark: LinkedInDark, alt: "Stephen Saada LinkedIn", link: "https://www.linkedin.com/in/stephen-saada-b33a4742/"},
  ]

  return (
    <React.Fragment>
      {socials.map((social, index) => {
        return (
          <div key={index} onMouseEnter={() => setHovered(social.alt)} onMouseLeave={() => setHovered(null)}>
            <SocialLink href={social.link}>
            <img src={hovered === social.alt ? social.iconDark : social.icon} alt={social.alt} />
          </SocialLink>
          </div>
        )
      })}
    

    </React.Fragment>
  )
}

export default SocialLinks
