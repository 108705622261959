import {createGlobalStyle} from 'styled-components';
import reset from 'styled-reset';
import ralewayRegularWoff from "../resources/fonts/Raleway-Regular.woff";
import ralewayRegularWoff2 from "../resources/fonts/Raleway-Regular.woff2";
import ralewayMediumWoff from "../resources/fonts/Raleway-Medium.woff";
import ralewayMediumWoff2 from "../resources/fonts/Raleway-Medium.woff2";
import ralewaySemiBoldWoff from "../resources/fonts/Raleway-SemiBold.woff";
import ralewaySemiBoldWoff2 from "../resources/fonts/Raleway-SemiBold.woff2";
import galvjiRegularWoff from "../resources/fonts/Galvji-Regular.woff";
import galvjiRegularWoff2 from "../resources/fonts/Galvji-Regular.woff2";
import galvjiBoldWoff from "../resources/fonts/Galvji-Bold.woff";
import galvjiBoldWoff2 from "../resources/fonts/Galvji-Bold.woff2";


const fontFace = (fontFamily, woff, woff2) => {
    return `
      @font-face {
          font-family: '${fontFamily}';
          src: url(${woff2}) format('woff2'),
          url(${woff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }
    `;
};

const GlobalStyle = createGlobalStyle`
  ${fontFace('Raleway Regular', ralewayRegularWoff, ralewayRegularWoff2)}
  ${fontFace('Raleway Medium', ralewayMediumWoff, ralewayMediumWoff2)}
  ${fontFace('Raleway SemiBold', ralewaySemiBoldWoff, ralewaySemiBoldWoff2)}
  ${fontFace('Galvij Regular', galvjiRegularWoff, galvjiRegularWoff2)}
  ${fontFace('Galvij Bold', galvjiBoldWoff, galvjiBoldWoff2)}
  
  body {
    font-display: swap;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  ${reset}
`;

export default GlobalStyle;
