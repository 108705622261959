import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ExternalLink from "../Core/ExternalLink"
//import { Link } from "gatsby"
import breakpoints from "../../styles/breakpoints"
import SocialLinks from "../Footer/SocialLinks"

const FooterBlock = styled.div`
  width: 100%;
  margin: auto;
  padding: 0 29px;
  max-width: 2000px;

  @media (min-width: ${breakpoints.md}) {
    padding: 0 76px;
  }
  @media (min-width: ${breakpoints.max}) {
    padding: 0 50px;
  }
`

const FooterContainer = styled.div`
  padding: 40px 0 31px 0;
  border-top: 1px solid ${colors.backgroundGrey};

  @media (min-width: ${breakpoints.md}) {
    padding: 40px 0 53px 0;
  }
`

const Title = styled.div`
  ${fonts.ralewayMedium};
  font-size: 14px;
  letter-spacing: 8.4px;
  text-transform: uppercase;
  color: ${colors.mediumGold};
  margin-bottom: 10px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 5px;
    line-height: 30px;
  }
`

const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${colors.grey};
  ${fonts.galvjiRegular};
  a {
    color: ${colors.grey};
    text-decoration: none;
    transition: 0.2s ease-in;
    :hover {
      color: ${colors.darkGrey};
    }
  }
  
`

const BottomText = styled.div`
  font-size: 12px;
  line-height: 30px;
  color: ${colors.lightGrey};
  ${fonts.galvjiRegular};
  margin-top: -10px;
  a {
    ${fonts.galvjiBold};
    color: ${colors.lightGrey};
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    a {
      ${fonts.galvjiRegular};
      text-decoration: underline;
    }
  }
`

const Block = styled.div`
  padding-bottom: 40px;

  @media (min-width: ${breakpoints.sm}) {
    padding-bottom: 30px;
  }
`

const Links = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: end;
`

const Footer = () => {
  return (
    <FooterBlock>
      <Container fluid>
        <FooterContainer>
          <Row>
            <Col sm={4}>
              <Block>
                <Title>Adresse</Title>
                <Text>
                  <ExternalLink href={"https://goo.gl/maps/sw6NeVkHUmzVwtqL8"}>
                    184 Boulevard Pereire,
                    <br />
                    75017 Paris, France
                  </ExternalLink>
                </Text>
              </Block>
            </Col>
            <Col sm={4}>
              <Block>
                <Title>Contact</Title>
                <Text>
                  <ExternalLink href={"tel:33618984589"}>
                    +33 6 18 98 45 89
                  </ExternalLink>
                </Text>
                <Text>
                  <ExternalLink href={"mailto:stephen@stephensaada.com"}>
                    stephen@stephensaada.com
                  </ExternalLink>
                </Text>
              </Block>
            </Col>
            <Col sm={4}>
              <Block>
                <Title>Suivez nous</Title>
                <Links>
                  <SocialLinks />
                </Links>
              </Block>
            </Col>
          </Row>
          <BottomText>
            © Stephen Saada. Tous droits réservés.
            {/* <Link to={"/privacy-policy"}>Privacy Policy</Link> */}
          </BottomText>
        </FooterContainer>
      </Container>
    </FooterBlock>
  )
}

export default Footer
