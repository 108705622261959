const colors = {
  white: '#FFFFFF',
  black: '#000000',
  lightGold: '#D9C07D',
  mediumGold: '#BAA15F',
  darkGold: '#B29651',
  green: '#526465',
  backgroundGrey: '#EAEAEA',
  lightBackgroundGrey: '#F5F5F5',
  lightGrey: '#A5A5A5',
  grey: '#717171',
  darkGrey: '#363636',
  navy: '#0B172E',
};

export default colors;