import React from "react";
import styled from 'styled-components';
import breakpoints from "../../styles/breakpoints";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: ${props => props.noPadding ? "0" : props.smallPadding ? "18px" : "30px"};
  padding-right: ${props => props.noPadding ? "0" : props.smallPadding ? "18px" : "30px"};
  max-width: ${breakpoints.max};
  margin: auto;
  
  @media(min-width: ${breakpoints.md}){
    padding-right: 76px;
      padding-left: 76px;
  }
  @media(min-width: ${breakpoints.max}){
    padding-right: 50px;
      padding-left: 50px;
  }
`;

const ContentContainer = ({children, noPadding, smallPadding}) => {
  return (
    <Container noPadding={noPadding} smallPadding={smallPadding}>
      {children}
    </Container>
  )
}

export default ContentContainer;
