import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import HeaderContent from "./HeaderContent";

const Header = (props) => (
  <StaticQuery
    query={graphql`
      query {
        links: allContentfulNavigation {
          edges {
            node {
              mobileNav {
                ctaLink
                ctaLabel
              }
              desktopNav {
                ctaLink
                ctaLabel
              }
            }
          }
        }
      }
    `}
    render={({links}) => {
      return (
        <React.Fragment>
          <HeaderContent {...props} mobileLinks={links.edges[0].node.mobileNav} desktopLinks={links.edges[0].node.desktopNav}/>
        </React.Fragment>
      );
    }}
  />
);

export default Header;
