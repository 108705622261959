import React, { useCallback, useEffect, useState } from "react"
import styled, {keyframes} from 'styled-components';
import colors from "../../styles/colors";
import { Link } from "gatsby";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import LightTitle from "../Core/LightTitle";
import WhiteLine from "../../resources/img/ui/white-line.svg";
import DarkLine from "../../resources/img/ui/dark-line.svg";
import WhiteLogoImage from "../../resources/img/logo-white.svg";
import DarkLogoImage from "../../resources/img/logo.svg";
import CloseIcon from "../../resources/img/ui/close.svg";
import BurgerIcon from "../../resources/img/ui/white-burger.svg";
import GoldBurgerIcon from "../../resources/img/ui/gold-burger.svg"
import { isBrowser, setBodyOverflow } from "../../services/browser/browser"

const MobileNav = styled.div`
  position: fixed;
  height: ${props => props.isOpen ? '100%' : '0'};
  width: 100%;
  background: ${colors.green};
  z-index: 11;
  overflow: hidden;
  transition: .3s;
  
  @media(min-width: ${breakpoints.sm}){
    display: none;
  }
`;

const Links = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.visible ? 1 : 0};
  transition: ${props => props.visible ? '0.4s ease-in' : '0'};
  transition-delay: ${props => props.visible ? '0.2s' : '0'};
  
  @media(min-width: ${breakpoints.sm}){
    display: none;
  }
`;

const HeaderLinks = styled.div`
  display: none;
  
  @media(min-width: ${breakpoints.sm}){
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const HeaderLink = styled(Link)`
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${props => props.dark ? colors.darkGrey : colors.white};
  ${fonts.galvjiRegular};
  text-decoration: none;
  margin-right: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &:last-of-type {
    margin-right: 0px;
  }
  
  &.active {
    ${fonts.galvjiBold};
  }
  
  span {
    &.active {
      ${fonts.galvjiBold};
    }
  }
`;

const Nav = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  //margin-right: auto;
  //margin-left: auto;
  z-index: 11;
  transition: 0.3s;
  background: ${props => props.dark ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0)'};
  padding: 30px 0px 15px;
  
  @media(min-width: ${breakpoints.sm}){
    padding: ${props => !props.isTop ? '35px 0px 20px 0px' : '50px 0px 20px 0px'};
    height: auto;
  }
  
  @media(min-width: ${breakpoints.lg}){
    padding: ${props => !props.isTop ? '35px 0px 20px 0px' : '50px 0px 20px 0px'};
  }
`;

const Img = styled.img`
  height: 1px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: ${props => props.hovered === true ? '30px' : '0'};
  transition: .2s;
  position: absolute;
  bottom: -6px;

`;

const Logo = styled.img`
  max-width: 212px;
  //display: ${props => props.dark ? 'block' : 'none'};
  height: 14px;
  
  @media(min-width: ${breakpoints.sm}){
    max-width: 220px;
    height: 16px;
    top: unset;
    left: unset;
  }
`;

const NavLogo = styled.img`
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9;
  max-width: 212px;
  height: 14px;
`;

const LinkBlock = styled.div`
  margin-bottom: ${props => props.extraSpace && '60px'};
  text-indent: 16px;
  @media(min-width: ${breakpoints.sm}){
    text-indent: 24px;
  }
`;

const IconBox = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 12;
  top: 30px;
  right: 30px;
  height: 14px;

  img {
    width: ${props => props.open ? '14px' : '21px'};
    height: ${props => props.open ? '14px' : '8px'};
  }
  
  @media(min-width: ${breakpoints.sm}){
    display: none;
  }
`;

const NavContent =styled.div`
  width: 100%;
  max-width: 2000px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 0px 0px 30px;
  a {
    display: flex;
  }
  
  @media(min-width: ${breakpoints.sm}){
    padding: 0px 50px 0px
  }

`


const HeaderContent = ({ mobileLinks, desktopLinks, darkStyle = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(null);
  const [isHomepage, setIsHomepage] = useState(true);
  const [isDarkStyle, setIsDarkStyle] = useState(darkStyle);
  const [pathSegment, setPathSegment] = useState("");
  const [isAtTop, setIsAtTop] = useState(true)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const segment = window.location.pathname.split("/")[1]
      setPathSegment(segment)
    }
  }, [])

  useEffect(() => {
    if (isBrowser()) {
      setBodyOverflow(isOpen);
    }
  }, [isOpen])

  const closeIfActive = (e) => {
    if (e.currentTarget.className.includes("active")) {
      e.preventDefault();
      setIsOpen(!isOpen)
    }
  }

  const handleScroll = useCallback(() => {
    let scrollTop = window.pageYOffset;

    if (darkStyle === true){
      if (scrollTop === 0) {
        setIsAtTop(true)
      }

    if (scrollTop >= 1) {
      setIsAtTop(false)
    }
      return;
    }

    if (scrollTop === 0) {
      setIsAtTop(true)
      setIsDarkStyle(false)
    }

    if (scrollTop > 1) {
      setIsDarkStyle(true)
      setIsAtTop(false)
    }

  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      let location = window.location;
      let isHomepage = location.pathname === '/';
      setIsHomepage(isHomepage);
      window.addEventListener("scroll", () => handleScroll())
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  })

  return (
    <React.Fragment>
      <Nav dark={isDarkStyle} isTop={isAtTop}>
      <NavContent>
            {isHomepage ?
              <Logo src={isDarkStyle ? DarkLogoImage : WhiteLogoImage} alt={"Stephen Saada Logo"} dark={isDarkStyle}/>
              :
              <Link to={"/"}>
                <Logo src={isDarkStyle ? DarkLogoImage : WhiteLogoImage} alt={"Stephen Saada Logo"} dark={isDarkStyle}/>
              </Link>
            }
          <HeaderLinks>
            {desktopLinks.map((link, index) => {
              return (
                <HeaderLink key={index} to={link.ctaLink} activeClassName={"active"}
                            onMouseEnter={() => setIsHovered(index)}
                            onMouseLeave={() => setIsHovered(null)}
                            dark={isDarkStyle ? 1 : 0}>
                  <span className={pathSegment === link.ctaLabel.toLowerCase() ? "active" : null}>{link.ctaLabel}</span>
                  <Img src={isDarkStyle ? DarkLine : WhiteLine} alt={"Line"} hovered={isHovered === index}/>
                </HeaderLink>
              )
            })}
          </HeaderLinks>
      </NavContent>
        </Nav>
      <IconBox onClick={() => setIsOpen(!isOpen)} open={isOpen}>
        <img src={!isOpen ? ( isHomepage && isAtTop ? BurgerIcon : GoldBurgerIcon) : CloseIcon} alt={isOpen ? "Close menu" : "Open Menu"}/>
      </IconBox>
      <MobileNav isOpen={isOpen}>
        {isHomepage ?
          <NavLogo src={WhiteLogoImage} onClick={() => setIsOpen(!isOpen)} alt={"Stephen Saada Logo"}/>
          :
          <Link to={"/"}>
            <NavLogo src={WhiteLogoImage} alt={"Stephen Saada Logo"}/>
          </Link>
        }
        <Links visible={isOpen}>
          {mobileLinks.map((link, index) => {
            return (
              <LinkBlock key={index} extraSpace={index === 3}>
                <LightTitle>
                  <Link to={link.ctaLink} onClick={closeIfActive.bind(this)} activeClassName={"active"}>{link.ctaLabel}</Link>
                </LightTitle>
              </LinkBlock>
            )
          })}
        </Links>
      </MobileNav>
    </React.Fragment>
  )
}

export default HeaderContent;
