import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GlobalStyle from '../../styles/global'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {BaseCSS} from 'styled-bootstrap-grid';
import {GridThemeProvider} from 'styled-bootstrap-grid';
import {ThemeProvider} from 'styled-components';
import {gsap} from 'gsap';
import {CSSPlugin} from 'gsap/CSSPlugin';
import Header from "./Header";
import Footer from "./Footer";

gsap.registerPlugin(CSSPlugin);

const PageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  max-width: 2000px;
  margin: auto;
  margin-top: 0;
`;

const GlobalPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
  },
  row: {
    padding: 0
  },
  col: {
    padding: 0
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1280,
      lg: 1280,
      md: 1280,
      sm: 1280,
      xs: 1280,
    },
  },
};

const styledTheme = {};

const Layout = ({children, darkStyle}) => (
  <React.Fragment>
    <ThemeProvider theme={styledTheme}>
      <GridThemeProvider gridTheme={gridTheme}>
        <GlobalStyle/>
        <BaseCSS/>
        <GlobalPageContainer>
          <Header darkStyle={darkStyle}/>
          <PageContainer>
            {children}
          </PageContainer>
          <Footer/>
        </GlobalPageContainer>
      </GridThemeProvider>
    </ThemeProvider>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
